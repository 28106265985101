'use client'

import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'

const Collapsible = CollapsiblePrimitive.Root

const { CollapsibleTrigger } = CollapsiblePrimitive

const { CollapsibleContent } = CollapsiblePrimitive

export { Collapsible, CollapsibleContent, CollapsibleTrigger }
