import { Check, Copy } from 'lucide-react'
import { useSession } from 'next-auth/react'
import { useEffect, useMemo, useState } from 'react'

import { Button } from '../button'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../hover-card'
import { SourceData } from './index'
import { useCopyToClipboard } from './use-copy-to-clipboard'
import PdfDialog from './widgets/PdfDialog'

const SCORE_THRESHOLD = 0.3
const PROD = !!process.env.NEXT_PUBLIC_CGN_PROD

const SourceNumberButton = ({ index }: { index: number }) => {
  return (
    <div className="text-xs w-5 h-5 rounded-full bg-gray-100 mb-2 flex items-center justify-center hover:text-white hover:bg-primary hover:cursor-pointer">
      {index + 1}
    </div>
  )
}

type NodeInfoType = {
  id: string
  url?: string
  metadata?: Record<string, unknown>
  content?: string
}

const NodeInfo = ({ nodeInfo }: { nodeInfo: NodeInfoType }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 1000 })
  if (nodeInfo.metadata) {
    // this is a node generated by the web loader or file loader,
    // add a link to view its URL and a button to copy the URL to the clipboard
    return (
      <div className="flex items-left my-2 flex-col gap-2">
        {!!nodeInfo.metadata.author && (
          <span className="text-xs">
            <strong>Author:</strong> {nodeInfo.metadata.author as string}
          </span>
        )}
        {!!nodeInfo.metadata.speaker && (
          <span className="text-xs">
            <strong>Speaker(s):</strong> {nodeInfo.metadata.speaker as string}
          </span>
        )}
        <a className="hover:text-blue-900 text-blue-500" href={nodeInfo.metadata.link as string} target="_blank">
          <span>{nodeInfo.metadata.title as string}</span>
        </a>
      </div>
    )
  } else if (nodeInfo.url) {
    // this is a node generated by the web loader or file loader,
    // add a link to view its URL and a button to copy the URL to the clipboard
    return (
      <div className="flex items-center my-2">
        <a className="hover:text-blue-900" href={nodeInfo.url} target="_blank">
          <span>{nodeInfo.url}</span>
        </a>
        <Button
          onClick={() => copyToClipboard(nodeInfo.url!)}
          size="icon"
          variant="ghost"
          className="h-12 w-12 shrink-0"
        >
          {isCopied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
        </Button>
      </div>
    )
  }

  // node generated by unknown loader, implement renderer by analyzing logged out metadata
  return <p>Sorry, unknown node type. Please add a new renderer in the NodeInfo component.</p>
}

export const ChatSources = ({ data }: { data: SourceData }) => {
  const [isProd, setIsProd] = useState<boolean>(true)
  const { data: session } = useSession()

  useEffect(() => {
    setIsProd(!(session as any)?.user?.isCGN)
  }, [session])

  const sources: NodeInfoType[] = useMemo(() => {
    // aggregate nodes by url or file_path (get the highest one by score)
    const nodesByPath: { [path: string]: NodeInfoType } = {}

    data.nodes
      ?.filter((node) => (node.score ?? 1) > SCORE_THRESHOLD)
      ?.sort((a, b) => (b.score ?? 1) - (a.score ?? 1))
      ?.forEach((node) => {
        const nodeInfo = {
          id: node.id,
          url: node.url,
          metadata: node.metadata,
          content: node.text,
        }
        const key = nodeInfo.url ?? nodeInfo.id // use id as key for UNKNOWN type
        if (!nodesByPath[key]) {
          nodesByPath[key] = nodeInfo
        }
      })

    return Object.values(nodesByPath)
  }, [data.nodes])

  const [activeNode, setActiveNode] = useState<NodeInfoType | null>(null)

  const handleClickOutside = (event: any) => {
    if (activeNode && !event.target.closest('.node-info-popup')) {
      setActiveNode(null)
    }
  }

  useEffect(() => {
    if (activeNode) {
      console.log('NODE', activeNode)
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [activeNode])

  if (sources.length === 0) return null

  return (
    <div className="space-x-2 text-sm">
      <span className="font-semibold">Sources:</span>
      <div className="inline-flex gap-1 items-center">
        {sources.map((nodeInfo: NodeInfoType, index: number) => {
          if (nodeInfo.url?.endsWith('.pdf')) {
            return (
              <PdfDialog
                key={nodeInfo.id}
                documentId={nodeInfo.id}
                url={nodeInfo.url!}
                trigger={<SourceNumberButton index={index} />}
              />
            )
          }
          return (
            <div key={nodeInfo.id} className="relative" onClick={() => setActiveNode(nodeInfo)}>
              <HoverCard>
                <HoverCardTrigger>
                  <SourceNumberButton index={index} />
                </HoverCardTrigger>
                {isProd && (
                  <HoverCardContent className="w-[320px]">
                    <NodeInfo nodeInfo={nodeInfo} />
                  </HoverCardContent>
                )}
              </HoverCard>
              {!isProd && activeNode === nodeInfo && (
                <div className="node-info-popup absolute left-0 top-5 w-[800px] overflow-y-auto bg-white rounded-sm p-8 shadow-lg">
                  <div className="flex min-h-full p-4">
                    <pre className="whitespace-pre-wrap">{JSON.stringify(nodeInfo, null, 2)}</pre>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
